import React from 'react'
import './Footer.css'
import InstagramLogo from '../../assets/instagram.png'
import FacebookLogo from '../../assets/fb.png'
import { Link } from 'react-router-dom'
import MediaQuery from 'react-responsive'

const Footer = () => {
    const getCopyrightYear = () => {
        const date = new Date();
        if (date.getFullYear() === 2021) return 2021
        return `2021 - ${date.getFullYear()}`
    }

    const facebookLink = "https://www.facebook.com/Top-Protection-Security-GmbH-998309850225222/"
    const instagramLink = "https://www.instagram.com/topprotectionsecurity/"

    return (
        <div
            className="Footer"
        >
            <MediaQuery minDeviceWidth={768} >
                <span>
                    Copyright &copy; {getCopyrightYear()}. All rights reserved. | <Link
                        to="/agb"
                        className="Footer-link"
                    >AGB</Link> | <Link
                        to="/contact"
                        className="Footer-link"
                    >Impressum
                        </Link> | <Link
                        to="/dsgvo"
                        className="Footer-link"
                    >Datenschutz
                        </Link>
                </span>
                <div
                    className="Footer-social-links"
                >
                    <a
                        href={facebookLink}
                        target="_blank"
                        rel="noreferrer"
                        className="Footer-social-link"
                    >
                        <img
                            className="Footer-social-link-image"
                            src={FacebookLogo}
                            alt="Facebook - Logo"
                        />
                    </a>
                    <a
                        href={instagramLink}
                        target="_blank"
                        rel="noreferrer"
                        className="Footer-social-link"
                    >
                        <img
                            className="Footer-social-link-image"
                            src={InstagramLogo}
                            alt="Instagram - Logo"
                        />
                    </a>
                </div>
                <span
                    className="Footer-designedBy"
                >
                    Designed by <a
                        href={`https://sera-solutions.lu?ref=${window.location.host}`}
                        target="_blank"
                        rel="noreferrer"
                        className="Footer-link"
                    >Sera Solutions</a>
                </span>
            </MediaQuery>
            <MediaQuery maxDeviceWidth={767} >
                <div
                    className="Footer-links-xs"
                >
                    <Link
                        className="Footer-link-xs"
                        to="/services"
                    >
                        Leistungen
                    </Link>
                    <Link
                        className="Footer-link-xs"
                        to="/about"
                    >
                        Über uns
                    </Link>
                    <Link
                        className="Footer-link-xs"
                        to="/dsgvo"
                    >
                        Datenschutz
                    </Link>
                    <Link
                        className="Footer-link-xs"
                        to="/agb"
                    >
                        AGB
                    </Link>
                    <Link
                        className="Footer-link-xs Footer-link-xs-stretch"
                        to="/contact"
                    >
                        Impressum
                    </Link>
                </div>
                <div
                    className="Footer-social-links"
                >
                    <a
                        href={facebookLink}
                        target="_blank"
                        rel="noreferrer"
                        className="Footer-social-link"
                    >
                        <img
                            className="Footer-social-link-image"
                            src={FacebookLogo}
                            alt="Facebook - Logo"
                        />
                    </a>
                    <a
                        href={instagramLink}
                        target="_blank"
                        rel="noreferrer"
                        className="Footer-social-link"
                    >
                        <img
                            className="Footer-social-link-image"
                            src={InstagramLogo}
                            alt="Instagram - Logo"
                        />
                    </a>
                </div>
                <span
                    className="Footer-copyright-xs"
                >
                    &copy; {getCopyrightYear()} RP Top Protection Security GmbH
                </span>
                <span
                    className="Footer-designedBy-xs"
                >
                    Designed by <a
                        href={`https://sera-solutions.lu?ref=${window.location.host}`}
                        target="_blank"
                        rel="noreferrer"
                        className="Footer-link"
                    >Sera Solutions</a>
                </span>
            </MediaQuery>
        </div>
    )
}

export default Footer