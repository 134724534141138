import React from 'react'
import cx from 'classnames'
import { Link } from 'react-router-dom'
import './ButtonLink.css'

export type ButtonLinkProps = {
    primary?: boolean
    children: string
    link: string
}

const ButtonLink = (props: ButtonLinkProps) => {
    return (
        <Link
            className={cx(
                'ButtonLink',
                {
                    'ButtonLink-primary': props.primary
                }
            )}
            to={props.link}
        >
            {props.children}
        </Link>
    )
}

export default ButtonLink