import React, { useState } from "react";
import "./ContactFormular.css";
import cx from "classnames";
import { Link } from "react-router-dom";

const ContactFormular = () => {
  const [acceptsAGB, setAcceptsAGB] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mailAddress, setMailAddress] = useState("");
  const [telephone, setTelephone] = useState("");
  const [message, setMessage] = useState("");

  const isInputValid =
    firstName.trim() !== "" &&
    lastName.trim() !== "" &&
    mailAddress.trim() !== "" &&
    telephone.trim() !== "" &&
    message.trim() !== "";

  const reset = () => {
    setAcceptsAGB(false);
    setFirstName("");
    setLastName("");
    setMailAddress("");
    setTelephone("");
    setMessage("");
  };

  const send = () => {
    const formData = new FormData();
    formData.append("firstName", firstName);
    formData.append("lastName", lastName);
    formData.append("mailAddress", mailAddress);
    formData.append("telephone", telephone);
    formData.append("message", message);

    const xhttp = new XMLHttpRequest();

    xhttp.onreadystatechange = function () {
      if (this.readyState === XMLHttpRequest.DONE) {
        if (this.status === 200) {
          reset();
          alert("Nachricht versendet!");
        } else if (this.status === 429) {
          alert(
            "Sie müssen sich mindestens 2 Minuten geduldigen bevor Sie die nächste Nachricht verschicken können!"
          );
        } else {
          alert(
            `Unerwarteter Fehler aufgetreten, bitte melden Sie dies beim Uns (Fehlercode: ${this.status}`
          );
        }
      }
    };

    xhttp.open(
      "POST",
      "https://www.topprotection-security.at/send-mail.php",
      true
    );
    xhttp.send(formData);
  };

  return (
    <form action={"#"} className="ContactFormular">
      <InputField
        placeholder="Vorname"
        onType={setFirstName}
        value={firstName}
        autoComplete="given-name"
      />
      <InputField
        placeholder="Nachname"
        onType={setLastName}
        value={lastName}
        autoComplete="family-name"
      />
      <InputField
        placeholder="E-Mail Adresse"
        onType={setMailAddress}
        value={mailAddress}
        autoComplete="email"
      />
      <InputField
        placeholder="Telefonnummer"
        onType={setTelephone}
        value={telephone}
        autoComplete="tel"
      />
      <InputField
        className="ContactFormular-spaned-item"
        multiline={true}
        placeholder="Nachricht"
        onType={setMessage}
        value={message}
      />
      <div className="ContactFormular-spaned-item ContactFormular-agb-question">
        <Checkbox
          id="acceptAGB"
          checked={acceptsAGB}
          onCheck={(isChecked) => setAcceptsAGB(isChecked)}
        />
        <label
          className="ContactFormular-acceptsAGB-label"
          onClick={() => setAcceptsAGB((value) => !value)}
        >
          Ich habe die{" "}
          <Link to="/agb" target="_blank">
            <u>
              <b>AGB</b>
            </u>
          </Link>{" "}
          und{" "}
          <Link target="_blank" to="/dsgvo">
            <u>
              <b>Datenschutzerklärung</b>
            </u>
          </Link>{" "}
          gelesen und akzeptiert
        </label>
      </div>
      <button
        className="ContactFormular-button"
        disabled={!acceptsAGB || !isInputValid}
        onClick={(evt) => {
          evt.preventDefault();
          send();
        }}
      >
        Senden
      </button>
    </form>
  );
};

type InputFieldProps = {
  placeholder: string;
  className?: string | undefined;
  multiline?: boolean;
  onType: (value: string) => void;
  value: string;
  autoComplete?: string;
};
const InputField = (props: InputFieldProps) => {
  if (props.multiline) {
    return (
      <div className={"InputField-container " + (props.className || "")}>
        <textarea
          className={cx("InputField", props.className, "InputField-multiline")}
          placeholder={props.placeholder}
          onChange={(evt) => props.onType(evt.target.value)}
          value={props.value}
          required
          autoComplete={props.autoComplete}
        />
        <div className="InputField-redAsterix">*</div>
      </div>
    );
  }

  return (
    <div className={"InputField-container " + (props.className || "")}>
      <input
        className={cx("InputField", props.className, {
          "InputField-multiline": props.multiline,
        })}
        required
        onChange={(evt) => props.onType(evt.target.value)}
        value={props.value}
        placeholder={props.placeholder}
        autoComplete={props.autoComplete}
      />
      <div className="InputField-redAsterix">*</div>
    </div>
  );
};

type CheckboxProps = {
  checked: boolean;
  onCheck: (isChecked: boolean) => void;
  id: string;
};

const Checkbox = (props: CheckboxProps) => {
  return (
    <span
      onClick={() => props.onCheck(!props.checked)}
      className={cx("Checkbox", {
        "Checkbox-checked": props.checked,
      })}
    />
  );
};

export default ContactFormular;
