import React from 'react'
import './AGB.css'

const AGB = () => {
    return (
        <div
            className="AGB"
        >
            <h1
                className="AGB-header"
            >Allgemeine Geschäftsbedingungen</h1>
            <h2
                className="AGB-subtext"
            >RP Top Protection Security GmbH (folgend „TPS“ genannt) Version 01.12.2014</h2>
            <div
                className="AGB-sets"
            >
                <h1>
                    1. Geltungsbereich
                </h1>
                <p>
                    Sämtliche Rechtsbeziehungen zwischen Auftraggeber (AG) und Auftragnehmer (TPS) unterliegen diesen „Allgemeinen Geschäftsbedingungen“ (AGB). Dies gilt auch für künftige Ergänzungs- und Folgeaufträge, auch wenn eine Bezugnahme im Einzelfall nicht ausdrücklich erfolgen sollte.
                </p>
                <h1>
                    2. Schriftform, Vertragsänderungen
                </h1>
                <p>
                    Veränderungen der hier angeführten AGB bzw. der mit dem AG vereinbarten Leistungen bedürfen ausnahmslos der Schriftform und gelten diese als Bestandteil des Vertrages. Vom Schriftlichkeitsgebot kann nur schriftlich abgegangen werden.
                </p>
                <h1>
                    3. Dienstausführung
                </h1>
                <p>
                    TPS bedient sich zur vereinbarten Leistungserfüllung seiner Mitarbeiter als Erfüllungsgehilfen. Die Auswahl des beschäftigten Personals und das Weisungsrecht liegt – ausgenommen bei Gefahr in Verzug oder anderslautenden besonderen, ausdrücklichen Vereinbarungen bei TPS. Die Geltung des Arbeitskräfteüberlassungsgesetzes für das Personal der TPS wird ausdrücklich ausgeschlossen. Durch die Vertragsbeziehungen zwischen den Vertragsparteien wird kein Betriebs- oder Betriebsteilübergang begründet.
                </p>
                <h1>
                    4. Leistungsumfang
                </h1>
                <p>
                    Die Konkretisierung des vereinbarten Leistungsumfangs ist von den Vertragsparteien in Form einer Dienstvorschrift zu definieren, welche als Grundlage für Dienstanweisungen der TPS für seine Erfüllungsgehilfen dient. Wurden vereinbarte Leistungen nicht schriftlich konkretisiert, erbringt die TPS die Leistung nach Kriterien der Zweckmäßigkeit im eigenen Ermessen.
                </p>
                <h1>
                    5. Meldeadressen
                </h1>
                <p>
                    Der AG ist verpflichtet, der TPS Änderungen seiner Anschrift und Veränderungen bei Kontaktpersonen unverzüglich schriftlich bekannt zu geben. Wird die Mitteilung unterlassen, so gelten Erklärungen auch dann als zugegangen, falls sie an die zuletzt bekannt gegebene Anschrift bzw. Kontaktperson übermittelt wurden.
                </p>
                <h1>
                    6. Zutrittsberechtigung
                </h1>
                <p>
                    Die für die Auftragsdurchführung notwendigen Schlüssel bzw. Technischen Hilfsmittel sind vom AG kostenlos und rechtzeitig in der erforderlichen Stückzahl zur Verfügung zu stellen. Eine verspätete oder unvollständige Übergabe sowie die Ersatzverweigerung von unbrauchbar gewordenen Zutrittsberechtigungen, entbinden den AG nicht von der Entgeltleistung.
                </p>
                <h1>
                    7. Subunternehmen
                </h1>
                <p>
                    Die TPS ist berechtigt, zur Erfüllung seiner Verpflichtungen auch andere gewerbliche Bewachungsunternehmen heranzuziehen.
                </p>
                <h1>
                    8. Hinweisschilder
                </h1>
                <p>
                    Bei Beginn der Leistung werden – soweit keine gegenteilige Anweisung vorliegt – die üblichen Hinweisschilder angebracht. Die Schilder bleiben Eigentum der TPS. Nach Auftragsbeendigung werden diese wieder entfernt, die TPS ist nicht verpflichtet, den früheren Zustand wiederherzustellen.
                </p>
                <h1>
                    9. Vertragsabschluss, Vertragsdauer
                </h1>
                <p>
                    Der Vertrag kommt durch Auftragserteilung des AG und durch Auftragsbestätigung bzw. Auftragsannahme durch die TPS schriftlich oder mündlich zustande. Mündliche Nebenabreden haben keine Gültigkeit. Soweit nichts Abweichendes schriftlich vereinbart ist, gilt für alle Verträge eine Laufzeit von zwölf Monaten. Wird ein Vertrag mit einer bestimmten Laufzeit nicht längstens drei Monate vor Ablauf schriftlich gekündigt, so verlängert er sich um die bisherige Vertragszeit.
                </p>
                <h1>
                    10. Leistungsentgelt
                </h1>
                <p>
                    Das Entgelt ist, soweit nichts anderes schriftlich vereinbart wurde, monatlich im Nachhinein ohne Abzug zur Zahlung fällig. Aufrechnungen oder Einbehalte von Rechnungsbeträgen sind ausgeschlossen, soweit diese Ansprüche nicht ausdrücklich anerkannt oder rechtskräftig gerichtlich festgestellt sind. Bei Zahlungsverzug werden 12 Prozent Verzugszinsen zuzüglich der anfallenden Mahn- und Einbringungskosten verrechnet. Ist der AG mit einer fälligen Zahlung mehr als 4 Wochen im Verzug, so ist die TPS berechtigt, für die Dauer der Säumnis die vereinbarten Leistungen einzustellen. Die TPS ist berechtigt, das Entgelt zu erhöhen, wenn eine Steigerung der Löhne im Bewachungsgewerbe oder eine allgemeine Kostensteigerung eintritt. Wird die Preiserhöhung durch eine staatliche Institution geregelt, gilt diese als genehmigt, ansonsten gilt die vom Wachunternehmer errechnete Erhöhung als vereinbart.
                </p>
                <h1>
                    11. Vorzeitige Vertragsauflösung
                </h1>
                <p>
                    Bei gänzlicher Aufgabe des Vertragsobjektes kann der AG – sofern keine Rechtsnachfolge stattfindet, den Vertrag unter Einhaltung einer Kündigungsfrist von einem Monat vorzeitig lösen. Handelt es sich lediglich um eine Standortverlegung, ist die Dienstleistung am neuen Standort fortzusetzen. Diesbezügliche Veränderungen sind der TPS unverzüglich schriftlich zur Kenntnis zu bringen. Bei vorzeitiger Vertragsauflösung hat der AG, Preisminderungen, die auf Grund einer längeren Vertragslaufzeit gewährt wurden, binnen Zwei Wochen zurück zu bezahlen. Die TPS ist aus wirtschaftlichen Gründen berechtigt, den Vertrag unter Einhaltung einer einmonatigen Kündigungsfrist aufzulösen. Preisminderungen, die auf Grund einer längeren Vertragslaufzeit gewährt wurden, werden dadurch nicht berührt und werden durch die TPS nicht in Rechnung gestellt. Es folgert sich daraus das der AG, bei Einhaltung der Kündigungsfrist durch die TPS, keinerlei Rechtsansprüche gegenüber der TPS stellen kann, ausgenommen bei schriftlicher Vereinbarung die gegenteiliges enthält. Die TPS wird daher Schuld und Klaglos gehalten. Bei Zahlungsverzug trotz Setzung einer Nachfrist kann die TPS den Vertrag mit sofortiger Wirkung auflösen.
                </p>
                <h1>
                    12. Leistungsunterbrechung
                </h1>
                <p>
                    Soweit unvorhergesehene Ereignisse es notwendig machen, kann von den vorgesehenen Leistungen Abstand genommen werden. Insbesondere kann die TPS in Fällen höherer Gewalt, bei Streik, im Kriegsfall und oder Mitarbeitermangels die Dienstleistungen, soweit deren Ausführung behindert wird, unterbrechen oder zweckentsprechend umstellen. Bei Mitarbeiter Mangel muss der AG unverzüglich durch die TPS in Kenntnis gesetzt werden und sein möglichstes für die Bereitstellung eines anderen Bewachungsunternehmens Sorge tragen. Dadurch eventuell entstehende Mehrkosten aufgrund höheren Stundenpreises, trägt bei nicht fahrlässiger Handlung durch die TPS, der AG. Der AG ist nicht verpflichtet, für die Dauer der Unterbrechung der Leistung Entgelt zu entrichten.
                </p>
                <h1>
                    13. Rechtsnachfolge
                </h1>
                <p>
                    Der AG verpflichtet sich, bei Übertragung des Vertragsobjekts auf einen Rechtsnachfolger, die TPS ist spätestens bis zur Objektübergabe schriftlich darüber zu informieren. Bei einem Unternehmensübergang tritt der Rechtsnachfolger in den Vertrag ein, es sei denn, die TPS spricht sich binnen 3 Monaten nach Bekanntgabe der Übernahme dagegen aus. Bei Tod des AG tritt der Rechtsnachfolger in den Vertrag ein, sofern der Vertragszweck nicht hauptsächlich auf persönliche Belange, insbesondere auf den Schutz der Person des AG abgestellt war. Durch eine Veränderung oder Rechtsnachfolge seitens der TPS wird der Vertrag nicht berührt.
                </p>
                <h1>
                    14. Reklamationen
                </h1>
                <p>
                    Reklamationen jeder Art, die sich auf die Vertragserfüllung beziehen, sind unverzüglich nach Feststellung, schriftlich der Betriebsleitung der TPS zwecks Abhilfe mitzuteilen. Bei nicht rechtzeitiger Mitteilung können Rechte aus solchen Beanstandungen nicht geltend gemacht werden. Erhebliche, den Auftragszweck gefährdende Verstöße der Vertragserfüllung berechtigen nur dann zur fristlosen Vertragsauflösung, wenn die TPS nicht binnen angemessener Frist, längstens jedoch binnen 7 Werktagen, für Abhilfe der schriftlichen Reklamation sorgt.
                </p>
                <h1>
                    15. Beschäftigung von TPS-Personal
                </h1>
                <p>
                    Der AG darf Personal, welches vom der TPS zur Dienstausführung beauftragt ist bzw. war, oder ihm hierfür vorgestellt wurde, während der Dauer des Vertrags zwischen AG und der TPS und ein Jahr nach dessen Ablauf weder abwerben, noch selbst oder durch Dritte beschäftigen. Verstößt der AG gegen diese Vereinbarung, ist er verpflichtet der TPS Ersatzkosten in der Höhe eines Bruttojahresentgeltes des betroffenen Mitarbeiters zu bezahlen. Als Berechnungsgrundlage wird der Durchschnittsverdienst der letzten drei Beschäftigungsmonate herangezogen.
                </p>
                <h1>
                    16. Haftung
                </h1>
                <p>
                    Die TPS haftet nur für grob fahrlässig oder vorsätzlich verursachte Schäden, welche durch ihn oder sein Personal bei Vertragserfüllung verursacht werden, jedoch nur bis zur Höhe von: € 5.000,– für Personenschäden und Sachbeschädigungen, insgesamt je Schadensfall; Für Schäden durch Umweltstörung übernimmt die TPS keine Haftung. Übernimmt die TPS im Rahmen eines Vertrages auch branchenfremde Leistungen beschränkt sich die Haftung auf 10% der angeführten Höchstbeträge. Ein Haftungsanspruch besteht nur dann, wenn der AG zum Zeitpunkt des Schadenfalles mit der Zahlung des fälligen Entgeltes nicht in Verzug ist.
                </p>
                <h1>
                    17. Haftungsausschlüsse
                </h1>
                <p>
                    Der Haftungsanspruch erlischt, wenn der AG den Schaden und die daraus resultierenden Ansprüche nicht unverzüglich – längstens aber innerhalb einer Woche ab Kenntnis vom Schaden und Schädiger – schriftlich anzeigt und nachweist bzw. der Anspruch nicht binnen drei Monaten nach Ablehnung gerichtlich geltend gemacht wird. Für andere als die angeführten Schäden haftet die TPS nicht, insbesondere auch nicht für Schäden, für die auf Grund der allgemeinen Versicherungsbedingungen der Haftpflichtversicherung kein Versicherungsschutz gewährt wird. Bei Verlust eines Schlüssels wird nur der Ersatz des verlorenen Schlüssels geleistet; es erfolgt kein Ersatz einer (Zentral) Schließanlage bzw. deren Kosten. Die Möglichkeit der Anfechtung wegen Verkürzung über die Hälfte wird ausgeschlossen.
                </p>
                <h1>
                    18. Versicherungsnachweis
                </h1>
                <p>
                    Die TPS ist verpflichtet, eine Haftpflichtversicherung im Rahmen der übernommenen Haftung, deren Grenze sich aus Ziffer 19 ergibt, abzuschließen. Der AG kann den Nachweis über den Abschluss einer solchen Versicherung verlangen.
                </p>
                <h1>
                    19. Datenschutz
                </h1>
                <p>
                    Der AG erteilt seine Zustimmung, dass personenbezogene Daten in Erfüllung dieses Vertrages von der TPS automationsunterstützt gespeichert und verarbeitet werden. Die TPS verpflichtet sich, zumutbare technische und organisatorische Maßnahmen zu treffen, um die Daten des AG im Sinne des Datenschutzgesetzes zu schützen und verpflichtet seine Mitarbeiter ausdrücklich zur Geheimhaltung der Daten, im Sinne des Datenschutzgesetz 2000.
                </p>
                <h1>
                    20. Salvatorische Klausel
                </h1>
                <p>
                    Sollten eine oder mehrere Bestimmungen dieser AGB rechtsunwirksam sein oder werden, wird die Wirksamkeit der übrigen Bestimmungen nicht berührt. In einem solchen Fall ist die ungültige, anfechtbare oder undurchführbare Bestimmung so zu ergänzen oder zu ersetzen, dass der mit der ungültigen, anfechtbaren und durchführbaren Bestimmung beabsichtigte, wirtschaftliche Zweck erreicht wird.
                </p>
                <h1>
                    21. Konsumentenschutz
                </h1>
                <p>
                    Diese AGB sind grundsätzlich für Rechtsgeschäfte zwischen Unternehmen konzipiert. Für Auftraggeber, die Verbraucher im Sinne des Konsumentenschutzgesetzes sind, gelten diese Allgemeinen Geschäftsbedingungen nach Maßgabe der Zulässigkeit nach dem Konsumentenschutzgesetz.
                </p>
                <h1>
                    22. Gerichtsstand
                </h1>
                <p>
                    Gerichtsstand: Ausschließlicher Gerichtsstand ist, soweit nicht anders zwingend, gilt, Landesgericht Klagenfurt/Kärnten/Österreich.
                </p>
                <h1>
                    23. Schlußbestimmung
                </h1>
                <p>
                    Der Auftraggeber stimmt der Verwendung seines Namens bzw. Firmenlogos für Werbezwecke und Referenzangaben von TPS zu. Diese Zustimmung kann seitens des Auftraggebers jederzeit schriftlich widerrufen werden.
                </p>
            </div>
        </div>
    )
}
// todo: read 22. Gerichtsstand

export default AGB