import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Footer from './components/Footer/Footer';
import NavigationBar from './components/NavigationBar/NavigationBar';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import Home from './pages/Home/Home';
import reportWebVitals from './reportWebVitals';
import Services from './pages/Services/Services';
import AboutUs from './pages/AboutUs/AboutUs';
import Contact from './pages/Contact/Contact';
import AGB from './pages/AGB/AGB';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import Service from './pages/Service/Service';
import DataProtection from './pages/DataProtection/DataProtection';

ReactDOM.render(
  <React.StrictMode>
    <Router>
        <ScrollToTop/>
        <div
            className="App"
        >
            <div>
                <NavigationBar/>
                <Switch>
                    <Route
                        path="/"
                        exact
                    >
                        <Home/>
                    </Route>
                    <Route
                        path="/services"
                    >
                        <Services/>
                    </Route>
                    <Route
                        path={"/service"}
                    >
                        <Service/>
                    </Route>
                    <Route
                        path="/about"
                    >
                        <AboutUs/>
                    </Route>
                    <Route
                        path="/contact"
                    >
                        <Contact/>
                    </Route>
                    <Route
                        path="/agb"
                    >
                        <AGB/>
                    </Route>
                    <Route
                        path="/dsgvo"
                    >
                        <DataProtection/>
                    </Route>
                    <Route
                        path="*"
                        render={() => ( <Redirect to="/" /> )}
                    />
                </Switch>
            </div>
            <Footer/>
        </div>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(process.env.NODE_ENV !== 'production' ? console.log : () => {});
