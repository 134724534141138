import React from 'react'
import ContactFormular from '../../components/ContactFormular/ContactFormular'
import './Contact.css'

const Contact = () => {
    return (
        <div
            className="Contact"
        >
            <div
                className="Contact-contact"
            >
                <h1>
                    Kontaktieren Sie uns !
                </h1>
                <ContactFormular/>
            </div>
            <div
                className="Contact-seperator"
            />
            <div
                className="Contact-impressum"
            >
                <h1>
                    Impressum
                </h1>
                <p
                    className="Contact-impressum-paragraph"
                >
                    RP Top Protection Security GmbH<br />
                    Schlosssiedlung 4<br />
                    9701 Rothenthurn/Kärnten/Österreich<br />
                </p>
                <p
                    className="Contact-impressum-paragraph"
                >
                    Geschäftsführer: Herr Raimund della Pietra<br />
                    Tel.: <a href="tel:+43 664 1029 775">+43 664 1029 775</a><br />
                    E-Mail: <a href="mailto:office@topprotection-security.at">office@topprotection-security.at</a>
                </p>
                <p
                    className="Contact-impressum-paragraph"
                >
                    UID: ATU 69200438<br />
                    FN: 426288w<br />
                    Gerichtsstand: Landesgericht Klagenfurt/Kärnten/Österreich
                </p>
            </div>
        </div>
    )
}

export default Contact