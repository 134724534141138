import React from 'react'
import './Services.css'
import ObjektSchutzImage from '../../assets/objektschutz.jpg'
import DetekteiImage from '../../assets/detektei.jpg'
import LimousinenserviceImage from '../../assets/limousinenservice.jpg'
import PersonenSchutzImage from '../../assets/personenschutz.jpg'
import SicherheitsberatungImage from '../../assets/sicherheitsberatung.jpg'
import TrainingImage from '../../assets/training.jpg'
import VeranstaltungsschutzImage from '../../assets/veranstaltungsschutz.jpg'
import { Link } from 'react-router-dom'
import cx from 'classnames'
import MediaQuery from 'react-responsive'

const Services = () => {
    const services = [
        ['/service?personenschutz', PersonenSchutzImage, "Bild Personenschutz", "Personenschutz"],
        ['/service?veranstaltungsschutz', VeranstaltungsschutzImage, "Bild Veranstaltungsschutz", "Veranstaltungsschutz"],
        ['/service?objektschutz', ObjektSchutzImage, "Bild Objektschutz", "Objektschutz"],
        ['/service?sicherheitsberatung', SicherheitsberatungImage, "Bild Sicherheitsberatung", "Sicherheitsberatung"],
        ['/service?training', TrainingImage, "Bild Training", "Training"],
        ['/service?limousinenservice', LimousinenserviceImage, "Bild Limousinenservice", "Limousinenservice mit Sicherheitsbackground"]
    ].map((data, idx) => (
        <ServiceLink
            className={cx("Services-list-item", {
                "Services-list-image-landscape": data[3] === "Training"
            })}
            link={data[0]}
            imgSrc={data[1]}
            alt={data[2]}
            key={idx}
        >
            {data[3]}
        </ServiceLink>
    ))

    return (
        <div
            className="Services"
        >
            <h1>Unsere Leistungen <span className="Services-additional-header">für Sie</span></h1>
            <MediaQuery minDeviceWidth={768} >
                <ServiceLink
                    link={'/service?detektei'}
                    imgSrc={DetekteiImage}
                    alt="Bild Detektei"
                    className="Services-header"
                >
                    Detektei
                </ServiceLink>
            </MediaQuery>
            <div
                className="Services-list"
            >
                <MediaQuery maxDeviceWidth={767} >
                    <ServiceLink
                        link={'/service?detektei'}
                        imgSrc={DetekteiImage}
                        alt="Bild Detektei"
                        className="Services-list-item"
                    >
                        Detektei
                    </ServiceLink>
                </MediaQuery>
                {services}
            </div>
        </div>
    )
}

type ServiceLinkProps = {
    imgSrc: string
    alt: string
    className?: string
    children: string
    link: string
}

const ServiceLink = (props: ServiceLinkProps) => {
    return (
        <Link
            to={props.link}
            className={cx("ServiceLink", {
                [props.className as string]: props.className
            })}
        >
            <div
                className="ServiceLink-text"
            >{props.children}</div>
            <img
                className="ServiceLink-image"
                src={props.imgSrc}
                alt={props.alt}
            />
        </Link>
    )
}

export default Services