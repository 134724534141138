import React from 'react'
import MediaQuery, { useMediaQuery } from 'react-responsive'
import HomepagePhoto from '../../assets/homepage.jpg'
import ButtonLink from '../../components/ButtonLink/ButtonLink'
import './Home.css'

const Home = () => {
    const isPhone = useMediaQuery({ maxDeviceWidth: 767 })
    const isLandscapePhone = useMediaQuery({ orientation: "landscape", maxDeviceHeight: 400 })

    return (
        <>
            <div
                className="Home"
            >
                <div
                    className="Home-banner"
                >
                    <div
                        className="Home-banner-background"
                    />
                    <span
                        className="Home-slogan"
                    >Ihre Sicherheit ist unsere Verantwortung</span>
                    <MediaQuery minDeviceWidth={768} >
                        <div
                            className="Home-lower-section"
                        >
                            <span
                                className="Home-sub-slogan"
                            >
                                Wir bieten Ihnen sämtliche Sicherheitsdienstleistungen in dieser Branche
                            </span>
                            <div
                                className="Home-links"
                            >
                                <ButtonLink
                                    link="/services"
                                >
                                    Unsere Leistungen
                                </ButtonLink>
                                <ButtonLink
                                    primary={true}
                                    link="/contact"
                                >
                                    Kontakt
                                </ButtonLink>
                            </div>
                        </div>
                    </MediaQuery>
                </div>
                <img
                    className="Home-background-image"
                    src={HomepagePhoto}
                    alt="man standing with crossed arms"
                />
            </div>
            {(isPhone || isLandscapePhone) && <>
                <span
                    className="Home-sub-slogan"
                >
                    Wir bieten Ihnen sämtliche Sicherheitsdienstleistungen in dieser Branche
                </span>
                <div
                    className="Home-links"
                >
                    <ButtonLink
                        link="/services"
                    >
                        Unsere Leistungen
                    </ButtonLink>
                    <ButtonLink
                        primary={true}
                        link="/contact"
                    >
                        Kontakt
                    </ButtonLink>
                </div>
            </>}
        </>
    )
}

export default Home