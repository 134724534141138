import React from 'react'
import './AboutUs.css'
import TeamImage from '../../assets/team.jpg'

const AboutUs = () => {
    return (
        <div
            className="AboutUs"
        >
            <h1>
                Über uns
            </h1>
            <p
                className="AboutUs-text"
            >
                Die Firma RP Top Protection Security GmbH verfügt über sämtliche Gewerbeberechtigungen des Sicherheitsgewerbes (Berufsdetektive-Bewachungsgewerbe) gemäß § 94 Z 62 GewO 1994.
                Die Kernkompetenzen des Unternehmens sind der Objekt- und Veranstaltungsschutz sowie der Personenschutz und die Detektei.
            </p>
            <p
                className="AboutUs-text"
            >
                Das Unternehmen beschäftigt zurzeit ca. 35 Mitarbeiter und ist österreichweit sowie auch International tätig.
                Bestens ausgebildete und erfahrene Mitarbeiter garantieren den bestmöglichen Erfolg in allen sensiblen Bereichen dieser Branche.
                In unserem Team befinden sich unter anderem vereidigte Straßenaufsichtsorgane, Brandschutzbeauftragte, Rettungssanitäter, Berufsdetektive/Assistenten und europäisch zertifizierte Personenschützer. Die Umsetzung der gesetzlich verankerten Covid-19 Bestimmungen, können wir durch unseren geprüften Covid-19 Beauftragten zur Gänze garantieren.
            </p>
            <p
                className="AboutUs-text"
            >
                Observationen, Aufspüren von Personen, Betrugsbekämpfung, Video Überwachung (unter strengen Auflagen im Rahmen der Gesetzlichen Bestimmungen) Beweisführung bei Straftaten sind unter vielen anderen täglicher Bestandteil unserer Arbeit.
                Diskretion und Verschwiegenheit stehen bei uns ganz oben auf unserer Liste.
            </p>
            <img
                className="AboutUs-image"
                src={TeamImage}
                alt="TP Protection Team"
            />
        </div>
    )
}

export default AboutUs