import React, { useState } from 'react'
import MediaQuery from 'react-responsive'
import './NavigationBar.css'
import Logo from '../../assets/logo.png'
import cx from 'classnames'
import {
    NavLink,
    Link
} from 'react-router-dom'


const NavigationBar = () => {
    const [ isNavbarOpen, setNavbarOpen ] = useState(false)


    const links = [
        ['Startseite', '/'],
        ['Leistungen', '/services'],
        ['Über uns', '/about'],
        ['Kontakt', '/contact']
    ].map((link, idx) => (
        <NavLink
            activeClassName="NavigationBar-link-active"
            to={link[1]}
            className="NavigationBar-link"
            key={idx}
            exact
        >
            {link[0]}
        </NavLink>
    ))

    return (
        <div
            className="NavigationBar"
        >
            <MediaQuery minDeviceWidth={768} >
                <Link
                    to={'/'}
                    className="NavigationBar-logo"
                >
                    <img
                        alt="Logo"
                        className="NavigationBar-logo-image"
                        src={Logo}
                    />
                    <div
                        className="NavigationBar-logo-text"
                    >
                        Top Protection<br /><b>Security</b>
                    </div>
                </Link>
                <nav
                    className="NavigationBar-links"
                >
                    {links}
                </nav>
            </MediaQuery>
            <MediaQuery maxDeviceWidth={767} >
                <Link
                    to={"/"}
                    className="NavigationBar-logo-image"
                >
                    <img
                        alt="Logo"
                        src={Logo}
                    />
                </Link>
                <div
                    className="NavigationBar-logo-text"
                >
                    Top Protection<br /><b>Security</b>
                </div>
                <Hamburger
                    className={"NavigationBar-hamburger"}
                    isOpen={isNavbarOpen}
                    onClick={() => setNavbarOpen((currentState => !currentState))}
                />
                <div
                    className={cx("NavigationBar-open-navbar", {
                        'NavigationBar-open-navbar-closed': !isNavbarOpen
                    })}
                    onClick={() => setNavbarOpen((currentState => !currentState))}
                >
                    {links}
                </div>
            </MediaQuery>
        </div>
    )
}

type HamburgerProps = {
    onClick: () => void
    isOpen: boolean
    className?: string | undefined
}

const Hamburger = (props: HamburgerProps) => {

       
    if (props.isOpen) return (
        <div
            className={cx("Hamburger", props.className)}
            onClick={() => props.onClick()}
        >
            <div
                className="Hamburger-close-Line Hamburger-close-Line-1"
            />
            <div
                className="Hamburger-close-Line Hamburger-close-Line-2"
            />
        </div>
    )

    return (
        <div
            className={cx("Hamburger", props.className)}
            onClick={() => props.onClick()}
        >
            <div 
                className="Hamburger-Line"
            />
            <div
                className="Hamburger-Line"
            />
            <div
                className="Hamburger-Line"
            />
        </div>
    )

}

export default NavigationBar